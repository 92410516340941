// Modules exported here will be included in bundle.js
// Modules exported here will be included in bundle.js

let cat_name = '';

// ログ出力
const c_log = (log) => {
  // console.log(log); 
}

function gtag(event, type, param) {
  const category = param['event_category'];
  const action = type;
  const label = param['event_label'];
  console.log(category + ',' + action + ',' + label);
  try {
    // window.parent._ga.spotEvent(category, action, label);
    _ga.spotEvent(category, action, label);
  } catch (error) {
    console.error("イベント発行エラー: " + error);
  }
}

//card gtag event 設定
export function card_gtags(data) {
  c_log(data);
  const tab_name = document.querySelector(".yxt-Nav-item.is-active").innerText.trim();
  let   sec_titl = data._config.isUniversal ? data._config._parentOpts._parentOpts.title : tab_name;
  const elem = data._container.querySelectorAll('a');
  c_log(elem);
  elem && elem.forEach(ele => {
    const classes = ele.classList;
    let tag = '';
    let tit = '';
    if (classes.contains('imageLink') || ele.querySelector('img')) {
      tag = '_画像';
      tit = data.result.title;
    }
    else if (classes.contains('HitchhikerCTA')) {
      tag = '_CTA';
      tit = ele.querySelector('div[class$="-iconLabel"]')?.innerText?.trim() ?? data.result.title;
    }
    else {
      tag = '_リンク';
      tit = ele.querySelector('div[class$="-title"]')?.innerText?.trim() ?? data.result.title;
    }
    if (sec_titl == 'Promotion') {
      sec_titl = 'プロモーション';
    }
    let act = 'Yext_' + tab_name + '_' + sec_titl + tag;
    // let tit = ele.innerText?.trim() ?? data.result.title;
    let url = ele.href;
    add_gtag_event(ele, act, cat_name, tit + '_' + url);
  });
}

function add_gtag_event_more(ele, act, cat) {
  ele.addEventListener('click', () => {
    const params = new URLSearchParams(window.location.search);  //url.searchParams;
    const label = 'もっと見る_' + params.get('query');
    //c_log('Fired gtag: ' + act + ',' + cat + ',' + label);
    gtag('event', act, {
      'event_category' : cat,
      'event_label' : label
    });
  });
}

function add_gtag_event(ele, act, cat, lab) {
  ele.addEventListener('click', () => {
    //c_log('Fired gtag: ' + act + ',' + cat + ',' + lab);
    gtag('event', act, {
      'event_category' : cat,
      'event_label' : lab
    });
  });
}

//詳細ボタンクリック
function on_ytx_Nav_more() {
  const ytx_Nav_more = document.getElementById('yxt-Nav-moreButton');
  if (ytx_Nav_more) {
    c_log('Yext_詳細クリック clickイベント登録');
    ytx_Nav_more.addEventListener('click', () => {
      c_log('Yext_詳細クリック');
      const params = new URLSearchParams(window.location.search);  //url.searchParams;
      // getメソッド
      const label = params.get('query');
      gtag('event', 'Yext_詳細クリック', {
        'event_category' : cat_name,
        'event_label' : label
      });
    });
  }
}
//タブクリック
function on_yxt_Nav_item() {
  const yxt_Nav_items = document.querySelectorAll('.yxt-Nav-item');
  yxt_Nav_items && yxt_Nav_items.forEach(item => {
    item.addEventListener('click', () => {
      c_log('Yext_タブクリック');
      gtag('event', 'Yext_タブクリック', {
        'event_category' : cat_name,
        'event_label' : item.innerText
      });
    });
  });
}
// Navigationのクリック取得
function on_yxt_Nav_click() {
  const obj_navigation = ANSWERS.components.getActiveComponent('Navigation');
  if (obj_navigation) {
    const orgonMount = obj_navigation.onMount;
    obj_navigation.onMount = () => {
      orgonMount.call(obj_navigation);
      on_ytx_Nav_more();
      on_yxt_Nav_item();
    }
  }
}
function on_yxt_SearchBar() {
  //SearchBar hook 処理
  const pos = '上部'
  const sb = ANSWERS.components.getActiveComponent('SearchBar');
  if (sb) {
    c_log('SearchBar');
    const queryEl = sb.queryEl;
    queryEl.addEventListener('focus', () => {
      c_log('onFocus');
      gtag('event', 'Yext_' + pos + '_検索枠アクティブ', {
        'event_category' : cat_name,
        'event_label' : pos
      });
    });
    sb.customHooks.onConductSearch = function(searchTerms) {
      c_log('onConductSearch');
      c_log(searchTerms);
      if (updateLocationFlag.flag) {
        c_log('現在地ボタン処理中での、検索実行は計測しない。');
        updateLocationFlag.flag = false;
      }
      else {
        gtag('event', 'Yext_' + pos + '_検索実行', {
          'event_category' : cat_name,
          'event_label' : searchTerms
        });
      }
    }
  }
  // universalのSearchBar下の「その他の検索はこちら」イベント
  const tab_name = document.querySelector(".yxt-Nav-item.is-active").innerText.trim();
  const ev = document.querySelector('.Answers-OriginalSearch > a');
  ev && add_gtag_event(ev, 'Yext_' + tab_name + '_その他の検索はこちら', cat_name, ev.innerText);
}

//検索後のもっと見るボタン: onUniversalSearchに設置
export function on_ytx_View_more() {
  const tab_name = document.querySelector(".yxt-Nav-item.is-active").innerText.trim();
  const sections = document.querySelectorAll('.universal-result-sections section');
  sections && sections.forEach(section => {
    //titleは, HitchhikerResultsGridThreeColumns-title や、HitchhikerResultsGridTwoColumns-titleLabel もある
    //const title = section.querySelector('.HitchhikerResultsStandard-titleLabel')?.textContent;
    const title = section.querySelector('div[class$="-titleLabel"]')?.textContent;
    const viewm = section.querySelector('a[class$="-viewMoreLink"]');
    viewm && add_gtag_event_more(viewm, 'Yext_' + tab_name + '_' + title + '_もっとみる', cat_name);
  });
}

//フィルター取得　
function onViewResultsButton() {
  const vrb = ANSWERS.components.getActiveComponent('ViewResultsButton');
  if (vrb) {
    const onMount = vrb.onMount;
    vrb.onMount = function() {
      const tab_name = document.querySelector(".yxt-Nav-item.is-active").innerText.trim();
      const params = new URLSearchParams(window.location.search);  //url.searchParams;
      const label = params.get('facetFilters');
      c_log('ViewResultsButton filters:' + label);
      gtag('event', 'Yext_' + tab_name + '_並び替えとフィルタ', {
        'event_category' : cat_name,
        'event_label' : label
      });
      onMount();
    }
  }
}

// 検索結果表示のイベント
export function on_ytx_searchParam(searchParams) {
  gtag('event', 'Yext_検索結果表示', {
    'event_category' : cat_name,
    'event_label' : searchParams.queryString
  });
}

// モバイルでのauto zoom off
function changeViewport() {
  const newViewportContent = "width=device-width, initial-scale=1, maximum-scale=1";
  // meta要素を取得します
  const metaViewport = document.querySelector("meta[name='viewport']");
  // content属性を新しい値に変更します
  metaViewport && metaViewport.setAttribute("content", newViewportContent);
}

// gtagの初期化: on-ready.jsで呼ぶ
export function init_gtags(categoryName = '[YBB]_インターネット') {
  cat_name = categoryName;
  on_yxt_SearchBar();
  on_ytx_Nav_more();
  on_yxt_Nav_item();
  onViewResultsButton();
  on_yxt_Nav_click();
  changeViewport();
}

// tag1749369
// location_biasの更新で、検索実行イベントが２回起動を回避するためのフラグ
// location_biasボタンのクリック後 trueにセットする
// 検索実行イベントが発生した時、このフラグ=trueの時は、イベント発行をスルーする
const updateLocationFlag = {
  set flag(value) {
    ANSWERS.core.storage.set('location-updating', value);
    //value ? console.log('set update location') : console.log('clear update location');
  },
  get flag() {
    return  ANSWERS.core.storage.get('location-updating');
  }
}

//Cardにmapが表示された場合、(sel-elmapで指定)、現在地を更新するボタンクリックをシミュレート
let not_locationbias = true;
export const updateLocation = (sel_elmap) => {
  const elmap = document.querySelector(sel_elmap);
  const updateLocationButton = document.querySelector('.js-locationBias-update-location');
  if (elmap && not_locationbias && updateLocationButton) {
    updateLocationButton.click();
    not_locationbias = false;
    updateLocationFlag.flag = true;
  }
}



